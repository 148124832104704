import React from 'react';
import './App.css';
import { animateScroll } from 'react-scroll';

const firebase = require('firebase');
require('firebase/firestore');

firebase.initializeApp({
  apiKey: "AIzaSyB_IwFemtlb0hQ7d_RcEWtFhj4-s8bS1vs",
  authDomain: "smjchat-e94a2.firebaseapp.com",
  projectId: "smjchat-e94a2",
});

const db = firebase.firestore();
const messagesDb = db.collection('messages');

function MessageBox(props) {
  return (
    <div id='MessageBox'>
      <MessageName userName={props.userName} />
      <MessageText text={props.text} />
    </div>
  );
}

function MessageName(props) {
  return (
    <h5>{props.userName}</h5>
  );
}

function MessageText(props) {
  return (
    <p>{props.text}</p>
  )
}

class MessageList extends React.Component {
  componentDidUpdate() {
    animateScroll.scrollToBottom({
      containerId: 'MessageList',
      duration: 300,
    });
  }
  render() {
    const messagesArray = Object.keys(this.props.messages).map(i => this.props.messages[i]);
    const messageRender = messagesArray.map((message, index) => 
      <MessageBox userName={message.userName} text={message.text} key={index} />
    );
    return (
      <div id='MessageList'>
        {messageRender}
      </div>
    );
  }
}

class SendForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(e) {
    this.setState({value: e.target.value});
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.handleAdd(this.state.value);
    this.setState({value: ''});
  }
 
  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <label>
          <input type='text' value={this.state.value} onChange={this.handleChange} />
        </label>
        <input type='submit' value="Submit" />
      </form>
    );
  }
}

function userSignedIn() {
  return !!firebase.auth().currentUser;
}

class SignInButton extends React.Component {
  constructor(props) {
    super(props);
    this.signInorOut = this.signInorOut.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      userName: (userSignedIn() ? firebase.auth().currentUser.displayName : null),
      text: (userSignedIn() ? 'Sign out' : 'Sign in with Google'),
    }; 
    firebase.auth().onAuthStateChanged(this.handleChange);
  }
  handleChange() {
    this.props.handleChange();
    this.setState({
      userName: (userSignedIn() ? firebase.auth().currentUser.displayName : null),
      text: (userSignedIn() ? 'Sign out' : 'Sign in with Google'),
    });
  }
  signInorOut() {
    if (userSignedIn()) this.signOut();
    else {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithPopup(provider).then(() => this.handleChange()).then(() => {
          messagesDb.onSnapshot(() => this.handleChange())
      });
    }
  }
  signOut() {
    firebase.auth().signOut().then(() => this.handleChange());
  }
  render() {
    return (
      <div>
        <h4>{userSignedIn() ? 'Signed in as ' + this.state.userName : 'Not signed in'}</h4>
        <button onClick={this.signInorOut}>{this.state.text}</button>
      </div>
    );
  }
}

export default class ChatBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {messages: []};
    if (userSignedIn()) {
      messagesDb.get().then(e => {
      this.setState({messages: e.docs.map(x => x.data()).sort((a, b) => a.time - b.time)});
      });
      messagesDb.onSnapshot(e => this.handleChange());
    }
    this.handleAdd = this.handleAdd.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    if (userSignedIn()) messagesDb.onSnapshot(e => this.handleChange());
  }
  handleChange() {
    if (userSignedIn()) {
      messagesDb.get().then(e => {
        this.setState({messages: e.docs.map(x => x.data()).sort((a, b) => a.time - b.time)});
      });
    }
    
  }
  handleAdd(text) {
    if (!userSignedIn()) {
      alert('You are not signed in');
      return;
    }
    messagesDb.add({
      userName: firebase.auth().currentUser.displayName,
      text: text,
      time: Date.now(),
    });
    messagesDb.onSnapshot(e => this.handleChange());
    this.handleChange();
  }
  render() {
    return (
      <div className='ChatBox'>
        <SignInButton handleChange={this.handleChange}/>
        <MessageList messages={this.state.messages} />
        <SendForm handleAdd={this.handleAdd}/>
      </div>
    );
  }
}

